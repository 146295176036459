import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectStatusComponent } from './select-status/select-status.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [SelectStatusComponent],
  exports: [SelectStatusComponent],
  imports: [
    CommonModule,
    MatSelectModule
  ]
})
export class SelectStatusModule { }
