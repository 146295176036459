import { Pipe, PipeTransform } from '@angular/core';
import { DynamicTableRow } from '../../public-api';

@Pipe({
  name: 'validateActiveRow'
})
export class ValidateActiveRowPipe implements PipeTransform {

  transform(row: DynamicTableRow, fnValidate: (cell: DynamicTableRow) => boolean): boolean {
    return fnValidate ? fnValidate(row) : false;
  }

}
