import { MfeFileSource, MfeSourceStatus, MfeSourceType } from '../models';
import { MfeElementType } from '../utils/consts';

export const loadSource = (currentSource: MfeFileSource, targetNodeId?: string): Promise<MfeFileSource> => {
  const isScript = currentSource.type === MfeSourceType.js;
  const element = document.createElement(MfeElementType[currentSource.type]);
  (element as HTMLScriptElement | HTMLLinkElement).type = currentSource.type;

  if (isScript) {
    (element as HTMLScriptElement).src = currentSource.src;
  } else {
    (element as HTMLLinkElement).href = currentSource.src;
    (element as HTMLLinkElement).rel = 'stylesheet';
  }

  // append sources
  appendSource(element, targetNodeId);

  return new Promise<MfeFileSource>((resolve: (s: MfeFileSource) => void, reject: (s: MfeFileSource) => void) => {
    element.onerror = (error: string | Event) => {
      reject(setSourceStates(currentSource, MfeSourceStatus.error, error));
    };
    element.onload = () => {
      resolve(setSourceStates(currentSource, MfeSourceStatus.loaded));
    };
  });
};

function setSourceStates(source: MfeFileSource, status: MfeSourceStatus, error?: string | Event): MfeFileSource {
  if (error) {
    console.error('error loading source:', source, error);
  }
  const res: MfeFileSource = { ...source };
  return {
    ...res,
    status,
    loaded: status === MfeSourceStatus.loaded
  };
}

function appendSource(element: HTMLElement, targetNodeId?: string) {
  if (targetNodeId) {
    const container = document.getElementById(targetNodeId);
    container?.parentNode?.insertBefore(element, container.nextSibling);
  } else {
    document.getElementsByTagName('head')[0].appendChild(element);
  }
}
