import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { FormErrorsDirective } from './form-error.directive';

@NgModule({
  declarations: [FormErrorsDirective],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [FormErrorsDirective]
})
export class FormErrorsDirectiveModule {}
