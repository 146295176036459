import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { BreadcrumbsService } from './services/breadcrumb.service';

@NgModule({
  declarations: [BreadcrumbsComponent],
  imports: [CommonModule, RouterModule],
  exports: [BreadcrumbsComponent]
})
export class BreadcrumbsModule {
  static forRoot(): ModuleWithProviders<BreadcrumbsModule> {
    return {
      ngModule: BreadcrumbsModule,
      providers: [BreadcrumbsService]
    };
  }
}
