import { Directive, ElementRef, Input, OnChanges, OnDestroy, Optional, Renderer2, Self } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ErrorMessages, ErrorTypes } from './definitions/enums/form-error.enum';

@Directive({
  selector: '[appFormErrors]'
})
export class FormErrorsDirective implements OnChanges, OnDestroy {
  @Input() customFormatErrors: Array<ErrorTypes>;
  @Input() customFormatFunction: Function;
  @Input('appFormErrors') set errors(errorList: ValidationErrors) {
    this.removeElements(this._elements);
    if (errorList) {
      this.handleErrorList(errorList);
    } else {
      this.renderer.removeClass(this._elementRef.nativeElement, this.hasErrorClass);
    }
  }

  hasErrorClass = 'has-errors';
  errorsViaInput = true;
  _elements: Array<HTMLElement> = [];
  subscribed = false;
  destroySubject$ = new Subject<void>();

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly renderer: Renderer2,
    @Optional() @Self() protected ngControl: NgControl
  ) {}

  initControlSubscription() {
    this.ngControl.valueChanges
      .pipe(
        takeUntil(this.destroySubject$),
        filter(() => !this.errorsViaInput)
      )
      .subscribe(() => {
        this.errors = this.ngControl.errors;
      });
  }

  ngOnChanges() {
    if ((this.ngControl?.touched || this.ngControl?.dirty) && !this.errorsViaInput) {
      this.errors = this.ngControl.errors;
    }

    if (this.ngControl && !this.subscribed) {
      this.initControlSubscription();
      this.errorsViaInput = false;
      this.subscribed = true;
    }
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }

  handleErrorList(errorList: ValidationErrors) {
    this.renderer.addClass(this._elementRef.nativeElement, this.hasErrorClass);
    Object.keys(errorList).forEach((error: ErrorTypes) => {
      const elementObj = this.errorElementFactory(this.getErrorMessage(error, errorList));

      this._elements.push(elementObj);
      this.renderer.appendChild(this.getParentNode(), elementObj);
    });
  }

  removeElements(elements: Array<HTMLElement>) {
    elements.forEach((elementObj) => {
      this.renderer.removeChild(this.getParentNode(), elementObj);
    });
    this._elements = [];
  }

  getParentNode(): HTMLElement {
    return this.renderer.parentNode(this._elementRef.nativeElement);
  }

  defaultFormatFunction(error: ErrorTypes, errorList: ValidationErrors): string {
    return ErrorMessages[error]
      ? ErrorMessages[error] +
          (errorList[error].requiredLength ||
            errorList[error].min ||
            errorList[error].max ||
            errorList[error].minlength ||
            errorList[error].maxlength ||
            '')
      : ErrorMessages.unknown;
  }

  getErrorMessage(error: ErrorTypes, errorList: ValidationErrors): string {
    return this.customFormatErrors && this.customFormatErrors.includes(error)
      ? this.customFormatFunction(error, errorList)
      : this.defaultFormatFunction(error, errorList);
  }

  errorElementFactory(message: string): HTMLElement {
    const formError = this.renderer.createElement('span');
    this.renderer.addClass(formError, 'form-error');
    const errorSmall = this.renderer.createElement('small');
    this.renderer.addClass(errorSmall, 'error');
    const errorMessage = this.renderer.createText(message);
    this.renderer.appendChild(errorSmall, errorMessage);
    this.renderer.appendChild(formError, errorSmall);

    return formError;
  }
}
