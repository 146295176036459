import { Component, Input } from '@angular/core';
import { FormElement } from '@rappi/common';
import { ALL_PROPERTIES_VALUE, DEFAULT_PROPERTY_VALUE } from '../../definitions/constants/constants';

@Component({
  selector: 'one-input',
  templateUrl: 'input.component.html',
  styleUrls: ['input.component.scss']
})
export class InputComponent extends FormElement<any> {
  @Input() name: string;
  @Input() readonly: boolean;
  @Input() autocomplete: 'on' | 'off';
  @Input() min: number;
  @Input() max: number;
  @Input() step: number;

  readonly allPropertiesValue = ALL_PROPERTIES_VALUE;
  readonly defaultValueProperty = DEFAULT_PROPERTY_VALUE;
}
