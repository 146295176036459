export enum StatusNames {
  active = 'status-active',
  declined = 'status-declined',
  pending = 'status-pending',
  scheduled = 'status-scheduled',
  ended = 'status-ended',
  review = 'status-review',
  paused = 'status-paused',
  waitingApproval = 'status-waiting-approval'
}
