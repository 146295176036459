import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormControlDirective } from '@angular/forms';
import { FormElement } from '@rappi/common';

@Component({
  selector: 'one-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DatepickerComponent extends FormElement<string> implements OnInit {
  
  @Input() min: Date;
  @Input() max: Date;
  @Input() label: string;
  @Input() errorMessages?: {[key: string]: string};
  control;
  
  ngOnInit(): void {
    this.control = (this.ngControl as FormControlDirective).form || this.ngControl.control;
  }

  get controlError(){
    return this.control.errors ? Object.keys(this.control.errors)[0] : null;
  }

}
