import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { McBreadcrumbsService } from './service/mc-breadcrumbs.service';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { McBreadcrumbsComponent } from './component/mc-breadcrumbs.component';
import { McBreadcrumbsConfig } from './service/mc-breadcrumbs.config';
export class McBreadcrumbsModule {
/**
 * @return {?}
 */
static forRoot() : ModuleWithProviders {
    return {
      ngModule: McBreadcrumbsModule,
      providers: [
        McBreadcrumbsService,
        McBreadcrumbsConfig
      ]
    };
  }
static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
  imports: [CommonModule, RouterModule],
  declarations: [ McBreadcrumbsComponent ],
  exports: [ McBreadcrumbsComponent ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function McBreadcrumbsModule_tsickle_Closure_declarations() {
/** @type {?} */
McBreadcrumbsModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
McBreadcrumbsModule.ctorParameters;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
