import { Observable } from 'rxjs/Observable';

import { Injectable } from '@angular/core';
import { IBreadcrumb } from '../mc-breadcrumbs.shared';

export interface IPostProcessFunc {
  (crumbs: IBreadcrumb[]) : Promise<IBreadcrumb[]> | Observable<IBreadcrumb[]> | IBreadcrumb[];
}
export class McBreadcrumbsConfig {
  postProcess : IPostProcessFunc
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function McBreadcrumbsConfig_tsickle_Closure_declarations() {
/** @type {?} */
McBreadcrumbsConfig.decorators;
/**
 * @nocollapse
 * @type {?}
 */
McBreadcrumbsConfig.ctorParameters;
/** @type {?} */
McBreadcrumbsConfig.prototype.postProcess;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
