import { MfeEventType } from "../models";

export function dispatchOrchestratorRoutingEvent(
  url: string,
  queryParams?: any,
  customElementName: string = '',
) {
  const args = { orchestratorRoute: `/${url}`, queryParams };
  const customEvent = getOrchestratorRoutingEvent(customElementName, args);
  dispatchEvent(customEvent);
}

function getOrchestratorRoutingEvent(
  customElementName: string,
  data: any
) {
  const args = {
    detail: {
      ...data,
    },
  };
  return new CustomEvent(
    `${customElementName}-${MfeEventType.OrchestratorRoutingEvent}`,
    args
  );
}
