import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormElement } from '@rappi/common';

@Component({
  selector: 'one-input-textarea',
  templateUrl: './input-textarea.component.html',
  styleUrls: ['./input-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InputTextareaComponent extends FormElement<string> {
  @Input() rows = 5;
  @Input() placeholder: string;
}
