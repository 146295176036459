import { Injectable } from '@angular/core';
import {
  BODY_REQUEST,
  EventName,
  EventSetUser,
  PrefixEvent,
  STORAGE_KEY,
  TagManagerLayer
} from './definitions/constants';
import { EncryptService } from '../encrypt/encrypt.service';
import { EventDataLayer, TriggerAmplitudeEntity } from './definitions/interfaces';

@Injectable({
  providedIn: 'root'
})
export class TagManagerEventsService {
  BODY_REQUEST = BODY_REQUEST;
  private readonly PREFIX = PrefixEvent;

  private _gtmUserId: string;

  constructor(private readonly _encryptService: EncryptService) {
    window[TagManagerLayer] = window[TagManagerLayer] || [];

    this._getGtmUserId();
  }

  send(category: string, action: string, label?: string): void {
    window[TagManagerLayer].push({ event: this.PREFIX + category, action, label: label || this._gtmUserId });
  }

  triggerAmplitudeEvent(trigger: TriggerAmplitudeEntity): void {
    this.dataLayerPush({
      event: EventName,
      eventType: trigger.EVENT_NAME,
      eventProperties: trigger.EVENT_PROPS,
      userProperties: { USER_ID: this._gtmUserId }
    });
  }

  setUserId(gtmUserId = this._gtmUserId): void {
    this.dataLayerPush({
      event: EventSetUser,
      userId: gtmUserId
    });
  }

  dataLayerPush(event: EventDataLayer): void {
    try {
      window[TagManagerLayer].push(event);
    } catch (err) {}
  }

  getOS(userAgent = window.navigator.userAgent, platform = window.navigator.platform): string | null {
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  isMobile(userAgent = navigator.userAgent, maxTouchPoints = navigator.maxTouchPoints): boolean {
    return (
      /Android|webOS|iPhone|iPad|iPod|IEMobile|Opera Mini/i.test(userAgent) ||
      (userAgent.match(/Mac/) && maxTouchPoints && maxTouchPoints > 2)
    );
  }

  private _getGtmUserId() {
    const country = this._encryptService.decryptString(localStorage.getItem(STORAGE_KEY.country), STORAGE_KEY.country);
    this._gtmUserId = `${localStorage.getItem(STORAGE_KEY.id)}-${country}`;
  }
}
