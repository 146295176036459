import { Observable } from 'rxjs/Observable';
import { McBreadcrumbsService } from '../service/mc-breadcrumbs.service';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { IBreadcrumb } from '../mc-breadcrumbs.shared';
import { Subscription } from 'rxjs/Subscription';
export class McBreadcrumbsComponent implements OnInit, OnDestroy {
/**
 * @param {?} service
 */
constructor(public service: McBreadcrumbsService) {}

  crumbs: IBreadcrumb[];

  subscriptions = new Array<Subscription>();
/**
 * @return {?}
 */
public ngOnInit(): void {

    const /** @type {?} */ s = this.service.crumbs$.subscribe((x) => {
      this.crumbs = x;
    });
  }
/**
 * @return {?}
 */
ngOnDestroy(): void {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'mc-breadcrumbs',
  template: `
    <ol *ngIf="crumbs.length" class="breadcrumb">
      <li *ngFor="let crumb of crumbs; let last = last" [ngClass]="{ 'active': last }" class="breadcrumb-item">
        <a *ngIf="!last" [routerLink]="crumb.path">{{ crumb.text }}</a>
        <span *ngIf="last">{{ crumb.text }}</span>
      </li>
    </ol>
  `
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: McBreadcrumbsService, },
];
}

function McBreadcrumbsComponent_tsickle_Closure_declarations() {
/** @type {?} */
McBreadcrumbsComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
McBreadcrumbsComponent.ctorParameters;
/** @type {?} */
McBreadcrumbsComponent.prototype.crumbs;
/** @type {?} */
McBreadcrumbsComponent.prototype.subscriptions;
/** @type {?} */
McBreadcrumbsComponent.prototype.service;
}


interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
