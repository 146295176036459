import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioCardComponent } from './radio-card.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [RadioCardComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports:[RadioCardComponent]
})
export class RadioCardModule { }
