import {
  Directive,
  Input,
  TemplateRef,
  ElementRef,
  HostListener,
  ComponentRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef,
  ConnectedPosition
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { CustomTooltipContentComponent } from '../../custom-tooltip-content/custom-tooltip-content.component';
import {
  ToolTipPositions as CustomTooltipPositions,
  FromYValue,
  FromXValue
} from '../../definitions/enums';
import { FromY, FromX } from '../../definitions/interface';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[appCustomTooltip]'
})
export class TooltipRendererDirective implements OnInit, OnDestroy {
  @Input() hoverTooltip = false;
  @Input() showToolTip = true;
  @Input('appCustomTooltip') text: string;
  @Input() contentTemplate: TemplateRef<ElementRef>;
  @Input() contextData: any;
  @Input() tooltipPosition: CustomTooltipPositions =
    CustomTooltipPositions.right;

  private readonly _subscriptions = new Subscription;
  private _isHover = false;
  private _overlayRef: OverlayRef;

  constructor(
    private readonly _overlay: Overlay,
    private readonly _overlayPositionBuilder: OverlayPositionBuilder,
    private readonly _elementRef: ElementRef
  ) {}

  @HostListener('mouseenter')
  public show() {
    if (this.showToolTip && (this._overlayRef && !this._overlayRef.hasAttached())) {
      const tooltipRef: ComponentRef<CustomTooltipContentComponent> = this._overlayRef.attach(
        new ComponentPortal(CustomTooltipContentComponent)
      );
      tooltipRef.instance.text = this.text;
      tooltipRef.instance.tooltipPosition = this.validatePosition(this.tooltipPosition);
      tooltipRef.instance.contextData = this.contextData;
      tooltipRef.instance.contentTemplate = this.contentTemplate;
      this.addHover(tooltipRef);
    }
  }

  addHover(tooltipRef:  ComponentRef<CustomTooltipContentComponent>) {
    if(this.hoverTooltip) {
      this._subscriptions.add(tooltipRef.instance.isHover.asObservable()
        .subscribe((val) => {
          this._isHover = val;
  
          if (!val) {
            this.closeTooltip();
            this._isHover = false;
          }
        })
      );
    }
  }

  validatePosition(position: string): CustomTooltipPositions {
    return CustomTooltipPositions[position] || CustomTooltipPositions.right;
  }

  @HostListener('mouseleave')
  public hide() {
    setTimeout(() => {
      if (!this._isHover) {
        this.closeTooltip();
      }
    }, 10);
  }

  ngOnInit() {
    this.initPosition();
  }

  initPosition() {
    const withPositions = this.getWithPositions(this.tooltipPosition);
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([withPositions]);

    this._overlayRef = this._overlay.create({ positionStrategy });
  }

  ngOnDestroy() {
    this.closeTooltip();
    this._subscriptions.unsubscribe();
  }

  getWithPositions(
    position: CustomTooltipPositions
  ): ConnectedPosition {
    switch (position) {
      case CustomTooltipPositions.top:
        return this.setConnectedPosition(
          FromYValue.center,
          FromYValue.top,
          FromYValue.center,
          FromYValue.bottom,
          0,
          -10
        );
      case CustomTooltipPositions.right:
        return this.setConnectedPosition(
          FromXValue.end,
          FromXValue.center,
          FromXValue.start,
          FromXValue.center,
          10
        );
      case CustomTooltipPositions.bottom:
        return this.setConnectedPosition(
          FromYValue.center,
          FromYValue.bottom,
          FromYValue.center,
          FromYValue.top,
          0,
          10
        );
      case CustomTooltipPositions.left:
        return this.setConnectedPosition(
          FromXValue.start,
          FromXValue.center,
          FromXValue.end,
          FromXValue.center,
          -10
        );
      default:
        return this.setConnectedPosition();
    }
  }

  setConnectedPosition(
    originX: FromX = FromXValue.end,
    originY: FromY = FromYValue.center,
    overlayX: FromX = FromXValue.start,
    overlayY: FromY = FromYValue.center,
    offsetX = 10,
    offsetY = 0
  ): ConnectedPosition {
    return {
      originX,
      originY,
      overlayX,
      overlayY,
      offsetX,
      offsetY
    };
  }

  closeTooltip() {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
