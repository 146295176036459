import {
  DynamicTableCellType,
  TableMessageType
} from '../../definitions/dynamic-table.enums';
import {
  CellsProperties,
  DynamicTableRow,
  MessageData,
  CellValue,
  ComponentProperties,
  TableMessage
} from '../../definitions/dynamic-table.models';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ElementRef,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  Inject,
  ViewEncapsulation
} from '@angular/core';
import { DynamicComponentDirective } from '../../resources/directives/dynamic-component.directive';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

type editableTextType = string | number | boolean;

// @dynamic
@Component({
  selector: 'one-dynamic-cells',
  templateUrl: './dynamic-cells.component.html',
  styleUrls: ['./dynamic-cells.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicCellsComponent implements OnInit, OnDestroy {
  @ViewChild(DynamicComponentDirective, { static: true })
  customComponentRef: DynamicComponentDirective;
  @ViewChild('Input') input: ElementRef;
  @Input() element: DynamicTableRow;
  @Input() config: CellsProperties;
  @Input() column: string;
  cell: CellValue;
  dynamicTableCellType = DynamicTableCellType;
  defaultData: MessageData;
  destroySubject$: Subject<void> = new Subject();
  counter = 0;

  isEditingActive: boolean;

  inputBtns = {
    checkedBtn: '/assets/images/master-data/checked-btn.svg',
    cancelBtn: '/assets/images/master-data/cancel-btn.svg',
    editBtn: '/assets/images/master-data/edit-btn.svg'
  };

  editableText: editableTextType;
  noPhotoUrl = '/assets/images/master-data/empty-product-image.svg';

  constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    @Inject(DOCUMENT) private readonly document: Document
  ) {}

  ngOnInit() {
    this.initCells();

    if (this.config?.type === DynamicTableCellType.customComponent) {
      this.initializeCustomComponent();

      if (this.config.subject) {
        this.config.subject
          .pipe(
            takeUntil(this.destroySubject$),
            filter((msg: TableMessage) => msg.data.id === this.element.id)
          )
          .subscribe((msg: TableMessage) => {
            if (msg.type === TableMessageType.updateCustomComponent) {
              this.initializeCustomComponent();
            } else if (msg.type === TableMessageType.customComponentMessage) {
              this.sendEventToSubscribers(
                TableMessageType.toTableMessage,
                msg.data
              );
            }
          });
      }
    }
  }

  initCells() {
    this.cell = this.element[this.column] as CellValue;
    if (this.cell) {
      this.isEditingActive = this.cell.disabled;
      this.defaultData = { id: this.element.id, column: this.column };
      this.editableText = this.cell.value;
    }
  }

  toggleEdition() {
    this.isEditingActive = !this.isEditingActive;
  }

  dialog(value: editableTextType) {
    if (this.cell && !this.cell?.disabled) {
      this.sendEventToSubscribers(TableMessageType.openDialog, { value });
    }
  }

  editable() {
    this.sendEventToSubscribers(TableMessageType.openDialog, {
      value: this.input.nativeElement.value,
      disabled: true
    });
  }

  dropdown($event) {
    this.setColumnValue($event);
    this.sendEventToSubscribers(TableMessageType.dropdownMessage);
  }

  checkbox($event) {
    this.setColumnValue($event.target);
    this.sendEventToSubscribers(TableMessageType.checkboxMessage, {
      status: $event.target.checked,
      inputs: this.cell.inputs
    });
  }

  setColumnValue($event) {
    this.cell.value = $event.checked;
  }

  initializeCustomComponent() {
    const configProps = this.config.properties as ComponentProperties;
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      configProps.component
    );
    const viewContainerRef = this.customComponentRef.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.setComponentProperties(configProps, this.cell, componentRef, 'inputs');
    this.setComponentProperties(
      configProps,
      this.cell,
      componentRef,
      'outputs'
    );
  }

  imageErrorHandler($event) {
    if ($event.target.src !== this.noPhotoUrl && this.counter < 3) {
      this.counter++;
      $event.target.src = this.noPhotoUrl;
    }
  }

  setComponentProperties(
    configProps: ComponentProperties,
    componentProps: CellValue,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    componentRef: ComponentRef<any>,
    type: string
  ) {
    if (configProps[type].length) {
      configProps[type].forEach((prop) => {
        if (Object.keys(componentProps[type]).includes(prop)) {
          componentRef.instance[prop] = componentProps[type][prop];
        }
      });
    }
  }

  sendEventToSubscribers(type: TableMessageType, data: MessageData = {}) {
    if (this.config.subject) {
      this.config.subject.next({
        type: type,
        data: { ...this.defaultData, ...data }
      });
    } else {
      console.warn(
        'You should consider implementing a subject for this type of cell.'
      );
    }
  }

  copyText(url: editableTextType) {
    url = encodeURI(String(url));
    const el = this.document.createElement('textarea');
    el.value = url;
    this.document.body.appendChild(el);
    el.select();
    this.document.execCommand('copy');
    this.document.body.removeChild(el);

    this.sendEventToSubscribers(TableMessageType.toastrMessage);
  }

  ngOnDestroy() {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}
