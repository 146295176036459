import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Subscription, Subject, Observable } from 'rxjs';
import { CollectionViewer, ListRange } from '@angular/cdk/collections';
import { takeUntil } from 'rxjs/operators';

export class LazyScrollableDataSource<T> extends DataSource<T> {
  destroySubject$: Subject<void> = new Subject();
  private _totalSize = 0;
  private _filterByNameValue: string;
  private _filterByCodesValue: string[] = [];
  private _cachedData: Array<T> = [];
  private _fetchedPages = new Set<number>();
  private readonly _dataStream = new BehaviorSubject<(T | undefined)[]>(this._cachedData);
  private readonly _subscription = new Subscription();
  private readonly loadingSubject = new BehaviorSubject<boolean>(false);
  private readonly searchResults = new BehaviorSubject<boolean>(false);
  private _initialLoad = true;
  private _requestSubscription: Subscription = new Subscription();

  constructor(
    private readonly _serviceWrapper: (page: number, ...args: any) => Observable<T[]>,
    private _pageSize = 10,
    private readonly _initPage = 0
  ) {
    super();
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    if (!this._cachedData.length && this._initialLoad) {
      this._initialLoad = false;
      this._fetchPage(this._initPage);
    }

    this._subscription.add(
      collectionViewer.viewChange.subscribe((range: ListRange) => {
        const offsetPage = (1 + this._pageSize) * this._initPage;
        const startPage = this._getPageForIndex(range.start + offsetPage);
        const endPage = this._getPageForIndex(range.end + offsetPage);

        for (let i = startPage; i <= endPage; i++) {
          this._fetchPage(i);
        }
      })
    );

    return this._dataStream.asObservable();
  }

  disconnect() {
    this._subscription.unsubscribe();
    this.destroySubject$.next();
    this.destroySubject$.complete();
    this._dataStream.complete();
    this.loadingSubject.complete();
    this.searchResults.complete();
  }

  public setPageSize(pageSize: number) {
    this._pageSize = pageSize;
  }

  public setFilterValue(filterByNameValue: string, filterByCodesValue?: string[]) {
    this._filterByNameValue = filterByNameValue;
    this._filterByCodesValue = filterByCodesValue;
    this.resetData();
    this._requestSubscription.unsubscribe();
    this._fetchPage(this._initPage);
  }

  public getTotalSize(): number {
    return this._totalSize;
  }

  public renderUpdate(data: T[]) {
    this._cachedData = data;
    this._dataStream.next(this._cachedData);
  }

  public getData(): Array<T> {
    return this._cachedData;
  }

  public getDataObservable(): Observable<T[]> {
    return this._dataStream.asObservable();
  }

  public getLoadingObservable(): Observable<boolean> {
    return this.loadingSubject.asObservable();
  }

  public resetData() {
    this._resetValues();
  }

  public getSearchResultsObservable(): Observable<boolean> {
    return this.searchResults.asObservable();
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize) + this._initPage;
  }

  private _fetchPage(page: number) {
    if (this._fetchedPages.has(page)) {
      return;
    } else {
      this._fetchedPages.add(page);
      this.loadingSubject.next(true);

      this._requestSubscription = this._serviceWrapper(
        page,
        this._pageSize,
        this._filterByNameValue,
        this._filterByCodesValue
      )
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(
          (res: Array<T>) => {
            this.loadingSubject.next(false);
            this._cachedData = this._cachedData.concat(res.filter((item: T) => item));
            this._totalSize = this._cachedData.length;
            if (this._filterByNameValue && !this._cachedData.length) {
              this.setFilterValue('');
              this.searchResults.next(false);
            } else {
              this._dataStream.next(this._cachedData);
              this.searchResults.next(Boolean(this._cachedData.length));
            }
          },
          () => this._fetchedPages.delete(page)
        );
    }
  }

  private _resetValues() {
    this._cachedData = [];
    this._totalSize = 0;
    this._fetchedPages = new Set<number>();
  }
}
