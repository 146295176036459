export enum ToolTipPositions {
  top = 'top',
  right = 'right',
  bottom = 'bottom',
  left = 'left'
}

export enum FromXValue {
  center = 'center',
  start = 'start',
  end = 'end',
}

export enum FromYValue {
  center = 'center',
  top = 'top',
  bottom = 'bottom',
}
