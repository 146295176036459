import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { TitleBarRouteData } from '../definitions/title-bar.models';
import { RouteDataKeys } from '../definitions/title-bar.enums';
import { Data } from '@angular/router';
import { routePersistingFields } from '../definitions/title-bar.constant';

@Injectable()
export class TitleBarService {
  routeData = new BehaviorSubject<TitleBarRouteData>({ actions: [] });

  constructor(private readonly router: Router, private readonly activatedRoute: ActivatedRoute) {
    this.router.events
      .pipe(filter((x) => x instanceof NavigationEnd))
      .subscribe(() => this.routeData.next(this.getRouteData(this.activatedRoute.root)));
  }

  private getRouteData(route: ActivatedRoute) {
    let current: ActivatedRoute = route;
    let previousSnapshotData: TitleBarRouteData = {} as TitleBarRouteData;

    while (current.children?.length > 0) {
      const currentData = current.snapshot?.data;
      const currentChildrenData = current.children[0].snapshot.data;

      if (currentData) {
        routePersistingFields.forEach((field) => {
          if (!currentChildrenData[field] && currentData[field] && !previousSnapshotData[field]) {
            previousSnapshotData = { ...previousSnapshotData, [field]: current.snapshot.data[field] };
          }
        });
      }

      current = current.children[0];
    }

    return this.getData({
      ...current.snapshot.data,
      ...(previousSnapshotData as Data)
    });
  }

  private getData(data: Data): TitleBarRouteData {
    if (!data) {
      return { actions: [] };
    }

    const state = this.router.getCurrentNavigation()?.extras.state;
    const actionKeys = Object.keys(RouteDataKeys);
    const dataKeys = ['actions', 'title', 'hideBreadcrumbs', 'hideModuleTitle', 'subtitle', 'description',
    'actionsPermissions'];
    const defaultValues = [[], '', false, false, '', ''];
    const routerData = {};
    actionKeys.forEach((key: string) => (routerData[key] = state?.[key] || data.actionsProperties?.[key]));
    dataKeys.forEach(
      (key: string, index: number) => (routerData[key] = state?.[key] || data[key] || defaultValues[index])
    );
    return routerData as TitleBarRouteData;
  }
}
