import { Component, OnInit } from '@angular/core';
import { FormElement } from '@rappi/common';
import { TimeValue } from '../definitions/interfaces';

@Component({
  selector: 'one-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent extends FormElement<TimeValue> implements OnInit {
  readonly pattern = '\d*';

  ngOnInit() {
    this._value = {
      hours: this.value?.hours ? this.value?.hours : '00',
      minutes: this.value?.minutes ? this.value?.minutes : '00'
    };
  }

  timeChange(target: HTMLInputElement, maxValue: number) {
    let newValue = target.value;
    if (newValue.length > 2) {
      newValue = newValue.substring(1);
    }
    this.value = {
      ...this.value,
      [target.name]: this.formatTime(this.getValue(newValue, maxValue))
    } as TimeValue;
    target.value = this.value[target.name];
  }

  getValue(value: string, maxValue: number): number {
    const newValue = Number(value)

    if (newValue >= maxValue) {
      return newValue % maxValue;
    } else if (newValue < 0 || isNaN(newValue)) {
      return 0;
    }

    return newValue;
  }

  formatTime(time: number): string {
    return time < 10 ? '0' + time : String(time);
  }
}
