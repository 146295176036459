import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FunctionExecutionModule } from '@rappi/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { SelectComponent } from './components/select.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ScrollingModule, MatProgressBarModule, FunctionExecutionModule],
  declarations: [SelectComponent],
  exports: [SelectComponent]
})
export class SelectModule {}
