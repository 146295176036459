import { NgModule, ModuleWithProviders } from '@angular/core';
import { TitleBarComponent } from './components/title-bar.component';
import { CommonModule } from '@angular/common';
import { TitleBarService } from './services/title-bar.service';

@NgModule({
  imports: [CommonModule],
  declarations: [TitleBarComponent],
  exports: [TitleBarComponent]
})
export class TitleBarModule {
  static forRoot(): ModuleWithProviders<TitleBarModule> {
    return {
      ngModule: TitleBarModule,
      providers: [TitleBarService]
    };
  }
}
