import { Component, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA
} from '@angular/material/snack-bar';
import { DataAlerts } from './definitions/data-alerts.model';
import { AlertsType } from './definitions/alerts-type.enum';

@Component({
  selector: 'one-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertsComponent {
  alertsType = AlertsType;
  constructor(
    public snackBarRef: MatSnackBarRef<AlertsComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: DataAlerts
  ) {}
}
