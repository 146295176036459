export enum MfeSourceType {
  js = 'text/javascript',
  css = 'text/css'
}

export enum MfeSourceStatus {
  unknown = 'unknown',
  loading = 'loading',
  complete = 'complete',
  loaded = 'Already Loaded',
  error = 'Error loading resource'
}

export interface MfeFileSource {
  name: string;
  src: string;
  type: MfeSourceType;
  loaded?: boolean;
  status?: MfeSourceStatus;
}
