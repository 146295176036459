import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';

import'rxjs/add/observable/fromPromise';

// Angular makes it impossible to make modules optional :(

// try {
//   const _ = r('lodash');
//   _template = _.template;
//   _templateSettings = _.templateSetting
// } catch (e) {
//   try {
//     _template = r('lodash.template');
//     _templateSettings = r('lodash.templatesettings');
//   } catch (e) {
//     _template = (y) => (x) => y;
//     _templateSettings = {};
//   }
// } finally {
//   _templateSettings.interpolate = /{{([\s\S]+?)}}/g;
// }


import * as template from 'lodash.template';
import * as templateSettings from 'lodash.templatesettings';

const /** @type {?} */ _ = {
  template: template,
  templateSettings: templateSettings
};

_.templateSettings.interpolate = /{{([\s\S]+?)}}/g;

export interface IBreadcrumb {
  text: string,
  path: string
}

declare var require: any;
/**
 * @param {?} module
 * @return {?}
 */
function r(module) {
  return require(`${name}`);
}
/**
 * @param {?} template
 * @param {?} binding
 * @return {?}
 */
export function stringFormat(template: string, binding: any): string {
  let /** @type {?} */ compiled = _.template(template);
  return compiled(binding);
}
/**
 * @param {?} value
 * @return {?}
 */
export function isPromise(value: any): boolean {
  return value && (typeof value.then === 'function');
}
/**
 * @template T
 * @param {?} value
 * @return {?}
 */
export function wrapIntoObservable<T>(value: T | Promise<T> | Observable<T>)
  : Observable<T> {

  if (value instanceof Observable)
    return value;

  if (isPromise(value)) {
    return Observable.fromPromise(Promise.resolve(value));
  }

  return Observable.of( /** @type {?} */((value as T)));
}
