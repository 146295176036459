import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputTextareaComponent } from './public-api';

@NgModule({
  declarations: [InputTextareaComponent],
  exports: [InputTextareaComponent],
  imports: [CommonModule]
})
export class InputTextareaModule {

}
