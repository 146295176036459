import { MfeAttribute, MfeEvent } from "../models";

function getElementByTagName(elName: string): Element {
  return document.getElementsByTagName(elName)[0];
}

export function initMfe(elName: string, elContainerName: string): Element {
  const element = document.createElement(elName);
  const elementContainer = document.getElementById(elContainerName);
  if (elementContainer) {
    if (elementContainer.children.length > 0) {
      elementContainer.removeChild(elementContainer.children[0]);
    }
    elementContainer.appendChild(element);
  }
  return element;
}

export function killMfe<T>(elName: string, events: MfeEvent[]) {
  events.forEach((event: MfeEvent) =>
    window.removeEventListener(
      `${elName}-${event.type}`,
      event.listener as EventListener
    )
  );
}

export function setMfeAttribute(elName: string, attribute: MfeAttribute) {
  const element = getElementByTagName(elName);
  if (element) {
    element.setAttribute(attribute.key, attribute.value);
  }
}

export function setMfeAttributes(elName: string, attributes: MfeAttribute[]) {
  attributes.forEach((attribute) => setMfeAttribute(elName, attribute));
}

export function setMfeEvents(elName: string, events: MfeEvent[]) {
  events.forEach((event: MfeEvent) =>
    window.addEventListener(
      `${elName}-${event.type}`,
      event.listener as EventListener
    )
  );
}
