import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FormElement } from '@rappi/common';
import { SelectStatus } from '../definitions/interfaces';

@Component({
  selector: 'one-select-status',
  templateUrl: './select-status.component.html',
  styleUrls: ['./select-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectStatusComponent extends FormElement<SelectStatus> {
  @Input() statusList: SelectStatus[] = [];
}
