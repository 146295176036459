import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { IBreadcrumb, stringFormat } from '../mc-breadcrumbs.shared';
import { Observable } from 'rxjs/Observable';
export class McBreadcrumbsResolver implements Resolve<IBreadcrumb[]> {
/**
 * @param {?} route
 * @param {?} state
 * @return {?}
 */
public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
     : Observable<IBreadcrumb[]> | Promise<IBreadcrumb[]> | IBreadcrumb[] {

    const /** @type {?} */ data = route.routeConfig.data;
    const /** @type {?} */ path = this.getFullPath(route);

    let /** @type {?} */ text = typeof (data.breadcrumbs) === 'string' ? data.breadcrumbs : data.breadcrumbs.text || data.text || path;
    text = stringFormat(text, route.data);

    const /** @type {?} */ crumbs : IBreadcrumb[] = [{
      text: text,
      path: path
    }];

    return Observable.of(crumbs);
  }
/**
 * @param {?} route
 * @return {?}
 */
public getFullPath(route: ActivatedRouteSnapshot) : string {
    const /** @type {?} */ relativePath = (segments: UrlSegment[]) => segments.reduce((a, v) => a += '/' + v.path, '');
    const /** @type {?} */ fullPath = (routes: ActivatedRouteSnapshot[]) => routes.reduce((a, v) => a += relativePath(v.url), '');

    return fullPath(route.pathFromRoot)
  }
}
