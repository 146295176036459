import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FormElement } from '@rappi/common';
import { ParamsData, RadioAppearance } from './definitions/radio-card';

@Component({
  selector: 'one-radio-card',
  templateUrl: './radio-card.component.html',
  styleUrls: ['./radio-card.component.scss']
})
export class RadioCardComponent extends FormElement<string>  implements OnInit {

  @Input() appearance = RadioAppearance.vertical;
  @Input() control: FormControl;
  @Input() dataSource: ParamsData[];
  @Input() gap: string;
  @Input() checkbox = false;
  @Input() cardMin = '233px';

  nameCode: string;

  ngOnInit(): void {
    this.nameCode = `${this.appearance}${Math.random() * 500}`;
  }
}
