import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';

import { MatDateRangePicker } from '@angular/material/datepicker';

import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'one-range-datepicker',
  templateUrl: 'range-datepicker.component.html',
  styleUrls: ['range-datepicker.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RangeDatepickerComponent<T> {
  range: FormGroup = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  @Input() set start(control: FormControl) {
    this.range.setControl('start', control);
  }
  get start(): FormControl {
    return this.range.get('start') as FormControl;
  }

  @Input() set end(control: FormControl) {
    this.range.setControl('end', control);
  }

  @Input() disabled: boolean; 

  get end(): FormControl {
    return this.range.get('end') as FormControl;
  }

  @ViewChild('picker') picker: MatDateRangePicker<T>;
}
