import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessorConnector } from '../control-value-accessor-connector/control-value-accessor-connector.class';

@Directive()
export class FormElement<T> extends ControlValueAccessorConnector<T> {
  @Input() label = '';
  @Input() placeholder = '';
  @Input() required: string;
  @Input() type: string;
  @Output('blur') blurEvent = new EventEmitter<any>();
  @Output('focus') focusEvent = new EventEmitter<any>();
}
