import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';

@Component({
  selector: 'one-data-title',
  templateUrl: './data-title.component.html',
  styleUrls: ['./data-title.component.scss']
})
export class DataTitleComponent implements AfterViewInit, OnChanges {
  @HostBinding('style.margin-bottom')
  marginBottom = '12px';

  @Input() titleText: string;
  @Input() subtitleText: string;
  @Input() titleColor: string;
  @Input() tooltipPosition = 'top';
  @Input() tooltipText: string;
  @Input() customTitleTemplate: TemplateRef<any>;
  @Input() isCollapsible = false;
  @Input() collapsed = false;

  @Output() toggle = new EventEmitter<boolean>();

  public ngAfterViewInit(): void {
    this.onToggle(this.collapsed);
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges.collapsed) {
      this.onToggle(simpleChanges.collapsed.currentValue);
    }
  }

  public onToggle(collapsed: boolean): void {
    this.collapsed = collapsed;
    this.marginBottom = this.isCollapsible && this.collapsed ? '0px' : '12px';
    this.toggle.emit(this.isCollapsible && this.collapsed);
  }
}
